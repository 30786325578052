import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function getQueryData(query) {
  console.log("query: ", query);
  console.log("query.data: ", query.data);
  console.log("query.data.data: ", query.data.data);
  const result = query.data?.data ?? query.data ?? null;
  console.log("result: ", result);
  return result;
}

export function useGetStatsPlayerTierClearCounts() {
  return useQuery({
    queryKey: ["stats_player_tier_clear_counts"],
    queryFn: () => fetchStatsPlayerTierClearCounts(),
    onError: (error) => {
      console.error("Failed to fetch player stats", error);
    },
  });
}

export function fetchStatsPlayerTierClearCounts() {
  return axios.get("/stats/player-tier-clear-counts");
}
