export const APP_URL = process.env.REACT_APP_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_URL = API_BASE_URL + "/api";
export const APP_NAME_SHORT = "GB Stats";
export const APP_NAME_LONG = "goldberries.net Stats";

export const TIER_INDICES = [2, 5, 8, 11, 14, 15, 16, 17, 18];
export const TIER_NAMES = [
  "Tier 0",
  "Tier 1",
  "Tier 2",
  "Tier 3",
  "Tier 4",
  "Tier 5",
  "Tier 6",
  "Tier 7",
  "Standard",
];
