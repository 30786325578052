import { Container, CssBaseline, Divider, Grid, Tab, TextField, Typography } from "@mui/material";
import { API_URL, TIER_INDICES, TIER_NAMES } from "./util/constants";
import { getQueryData, useGetStatsPlayerTierClearCounts } from "./hooks/useApi";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useState } from "react";

axios.defaults.baseURL = API_URL;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <Container maxWidth="md" sx={{ mb: 1 }}>
        <h1>goldberries.net Stats</h1>
        <Typography variant="body1">
          This page fetches the official goldberries.net{" "}
          <Link href="https://goldberries.net/api-docs">API</Link>, sorts the data and displays it in a nicer
          to look at format.
        </Typography>
        <StatsPage />
      </Container>
    </QueryClientProvider>
  );
}

export function StatsPage() {
  const query = useGetStatsPlayerTierClearCounts();

  if (query.isLoading) {
    return <Typography variant="h5">Loading...</Typography>;
  } else if (query.isError) {
    return <ErrorDisplay error={query.error} />;
  }

  const data = getQueryData(query);
  console.log("data: ", data);
  const dataJSON = JSON.stringify(data, null, 2);

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <MostByTier data={data} />
      <Divider sx={{ my: 2 }} />
      <AllPlayersClears data={data} />
    </>
  );
}

function MostByTier({ data }) {
  const [name, setName] = useState("");
  const foundPlayer = data.find((row) => row.player.name.toLowerCase() === name.toLowerCase());
  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mt: 1 }}>
        Most Clears by Tier
      </Typography>
      <Typography variant="body1" gutterBottom>
        Wanna know where you are? Enter your name below to find out!
      </Typography>
      <TextField fullWidth label="Player Name" value={name} onChange={(e) => setName(e.target.value)} />

      {!foundPlayer && name && (
        <Typography variant="body1" gutterBottom>
          Player with name "{name}" not found.
        </Typography>
      )}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {TIER_INDICES.map((tier_index, i) => {
          const sortedData = data.slice().sort((a, b) => b.clears[tier_index] - a.clears[tier_index]);
          return (
            <Grid item key={tier_index} xs={12} md={4}>
              <MostByTierGroup
                tier_index={tier_index}
                index={i}
                data={sortedData}
                highlightPlayer={foundPlayer}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
function MostByTierGroup({ tier_index, index, data, highlightPlayer }) {
  const n1 = data[0];
  const n2 = data[1];
  const n3 = data[2];
  const next7 = data.slice(3, 10);

  const gold = "#FFD700";
  const silver = "#C0C0C0";
  const bronze = "#CD7F32";

  const playerIndex = data.findIndex((row) => row.player.id === highlightPlayer?.player.id);
  const playerRow = playerIndex >= 0 ? data[playerIndex] : null;

  return (
    <>
      <Typography variant="h5">{TIER_NAMES[index]}</Typography>
      <ul style={{ listStyle: "none" }}>
        <MostByTierGroupRow
          tier_index={tier_index}
          index={0}
          row={n1}
          highlightPlayer={highlightPlayer}
          fontSize="150%"
          color={gold}
        />
        <MostByTierGroupRow
          tier_index={tier_index}
          index={1}
          row={n2}
          highlightPlayer={highlightPlayer}
          fontSize="130%"
          color={silver}
        />
        <MostByTierGroupRow
          tier_index={tier_index}
          index={2}
          row={n3}
          highlightPlayer={highlightPlayer}
          fontSize="110%"
          color={bronze}
        />
        {next7.map((row, i) => (
          <MostByTierGroupRow
            key={row.player.id}
            tier_index={tier_index}
            index={i + 3}
            row={row}
            highlightPlayer={highlightPlayer}
          />
        ))}
        {playerRow && (
          <>
            <Divider sx={{ my: 1 }} />
            <MostByTierGroupRow
              tier_index={tier_index}
              index={playerIndex}
              row={playerRow}
              fontSize="110%"
              color="#0066cc"
            />
          </>
        )}
      </ul>
    </>
  );
}
function MostByTierGroupRow({
  tier_index,
  index,
  row,
  fontSize = "100%",
  color = "#212121",
  highlightPlayer = null,
}) {
  const { player, clears } = row;
  const doHighlight = highlightPlayer && player.id === highlightPlayer.player.id;
  color = doHighlight ? "#00cc00" : color;
  const bold = doHighlight ? "bold" : "normal";
  return (
    <li>
      <span style={{ fontSize, color, fontWeight: bold }}>
        #{index + 1} <PlayerLink player={player} style={{ color: doHighlight ? color : undefined }} /> -{" "}
        <span style={{ fontWeight: "bold" }}>{clears[tier_index]}</span>
      </span>
    </li>
  );
}

function AllPlayersClears({ data }) {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      resizable: false,
      disableReorder: true,
      valueGetter: (value, row) => row.player.name,
      renderCell: (params) => {
        return <PlayerLink player={params.row.player} />;
      },
    },
  ];
  TIER_INDICES.forEach((tier_index, i) => {
    columns.push({
      field: `tier_${tier_index}`,
      headerName: TIER_NAMES[i],
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "number",
      sortingOrder: ["desc", null],
      resizable: false,
      disableColumnMenu: true,
      disableReorder: true,
      valueGetter: (value, row) => row.clears[tier_index],
    });
  });
  columns.push({
    field: `total`,
    headerName: "Total",
    flex: 1,
    align: "center",
    headerAlign: "center",
    type: "number",
    sortingOrder: ["desc", null],
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
  });

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mt: 1 }}>
        Total Clears by Player
      </Typography>
      <DataGrid
        rows={data}
        columns={columns}
        sx={{
          [`& .${gridClasses.row}.even`]: {
            backgroundColor: "rgba(0, 0, 0, 0.07)",
          },
          [`& .${gridClasses.row}.odd`]: {
            backgroundColor: "rgba(255, 255, 255, 0.00)",
          },
        }}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
        autosizeOptions={{
          columns: ["name"],
          includeOutliers: true,
          includeHeaders: true,
        }}
        disableRowSelectionOnClick
        getRowId={(row) => row.player.id}
      />
    </>
  );
}

export function ErrorDisplay({ error }) {
  return (
    <>
      <Typography variant="h5">Error</Typography>
      <code>{error.message}</code>
    </>
  );
}

function PlayerLink({ player, style = {} }) {
  return (
    <Link href={`https://goldberries.net/player/${player.id}`} style={{ fontWeight: "bold", ...style }}>
      {player.name}
    </Link>
  );
}
function Link({ href, children, style = {} }) {
  return (
    <a href={href} target="_blank" rel="noopener" className="styled-link" style={style}>
      {children}
    </a>
  );
}
